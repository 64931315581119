import { Text } from '@resident-advisor/design-system'
import PropTypes from 'prop-types'
import useLocaleDateFormat from './useLocaleDateFormat'
import formats from './date-formats'

const DateComponent = ({
  date,
  format = formats.Standard,
  uppercase = true,
  noStyle = false,
  ...props
}) => {
  const formattedDate = useLocaleDateFormat({ date, format })

  if (noStyle) {
    return <>{formattedDate}</>
  }

  return (
    <Text uppercase={uppercase} {...props}>
      {formattedDate}
    </Text>
  )
}

DateComponent.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  format: PropTypes.string,
  uppercase: PropTypes.bool,
  noStyle: PropTypes.bool,
}

DateComponent.Formats = formats
DateComponent.displayName = 'Date'

export default DateComponent
