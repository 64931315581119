import PropTypes from 'prop-types'
import { Stack } from '@resident-advisor/design-system'
import Padded from './Padded'

/*
 * Stack with standard padding applied to it's childen
 */
const PaddedStack = ({ children, ...props }) =>
  children && (
    <Stack {...props}>
      <Padded>{children}</Padded>
    </Stack>
  )

PaddedStack.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PaddedStack
