import PropTypes from 'prop-types'
import { TwitterTweetEmbed } from 'react-twitter-embed'
import isString from 'lodash/isString'

const getTweetId = (href) => {
  if (!href || !isString(href)) {
    return null
  }

  const noQuery = href.split('?')[0]

  const sections = noQuery.split('/')

  return sections[sections.length - 1]
}

const TwitterEmbed = ({ href }) => {
  const tweetId = getTweetId(href)

  if (!tweetId) {
    return null
  }

  return (
    <TwitterTweetEmbed
      options={{ width: '100%', maxWidth: '100%' }}
      tweetId={tweetId}
    />
  )
}

TwitterEmbed.propTypes = {
  href: PropTypes.string.isRequired,
}

export default TwitterEmbed
