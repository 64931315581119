import PropTypes from 'prop-types'
import {
  FaceFlatIcon,
  variants,
  EmptyState,
} from '@resident-advisor/design-system'
import errorMessages from 'messages/errors'
import { useIntl } from 'react-intl'
import testIds from 'enums/testIds'
import { SpaceProps } from 'styled-system'

// This component is used in the QueryResultHandler as a function
// so we need to have a default (empty) props object for destructuring
const NoResults = ({ title, subtitle, py = 6 }: NoResultsProps = {}) => {
  const intl = useIntl()

  return (
    <EmptyState
      IconComponent={FaceFlatIcon}
      titleTextVariant={variants.text.heading.l}
      title={title ?? intl.formatMessage(errorMessages.noResults)}
      subtitle={subtitle ?? intl.formatMessage(errorMessages.noResultsTryAgain)}
      py={py}
      data-testid={testIds.genericNoResults}
    />
  )
}

type NoResultsProps = {
  title?: string
  subtitle?: string
  py?: SpaceProps['py']
}

NoResults.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  py: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
}

export default NoResults
