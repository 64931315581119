import Date from 'components/generic/date'
import parseISO from 'date-fns/parseISO'
import isThisYear from 'date-fns/isThisYear'

const toDateFormat = (date) => {
  let dateToUse = date
  if (typeof date === 'string') {
    dateToUse = parseISO(date)
  }
  return dateToUse
}

const dateFormat = (date) =>
  isThisYear(toDateFormat(date))
    ? Date.Formats.Standard
    : Date.Formats.StandardWithYear

export { dateFormat, toDateFormat }
