import PropTypes from 'prop-types'
import Head from 'next/head'
import testIds from 'enums/testIds'

const InfiniteScrollPaginationSEO = ({ headPaginationAttributes }) =>
  headPaginationAttributes &&
  typeof headPaginationAttributes === 'object' && (
    <div data-testid={testIds.infiniteScrollPaginationSEO}>
      <Head>
        {Object.entries(headPaginationAttributes).map(
          ([key, value]) => value && <link key={key} rel={key} href={value} />
        )}
      </Head>
    </div>
  )

InfiniteScrollPaginationSEO.propTypes = {
  headPaginationAttributes: PropTypes.shape({
    prev: PropTypes.string,
    next: PropTypes.string,
  }).isRequired,
}

export default InfiniteScrollPaginationSEO
