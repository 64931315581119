import { useRouter } from 'next/router'
import {
  addMonths,
  addYears,
  format,
  getYear,
  getMonth,
  isAfter,
  isBefore,
} from 'date-fns'
import isThisMonth from 'date-fns/isThisMonth'
import isThisYear from 'date-fns/isThisYear'
import isFuture from 'date-fns/isFuture'
import { useServerTime } from 'context/ServerTimeContext'
import { queryStringSetRelative } from 'lib/queryStringSet'
import useDateFnsLocale from 'hooks/useDateFnsLocale'

const useDateArchiveNavigation = (
  options = {
    modes: {
      [NAVIGATION_MODES.year]: false,
      [NAVIGATION_MODES.month]: true,
    },
    startDate: null,
    endDate: null,
    getLinkParams: null,
  }
) => {
  const { query, asPath } = useRouter()
  const serverTime = useServerTime()
  const locale = useDateFnsLocale()

  const { year: queryYear, month: queryMonth } = query

  const serverYear = getYear(serverTime)
  const serverMonth = getMonth(serverTime)

  const navigationMode = getNavigationMode(queryMonth, options)

  const selectedYear = getSelectedYear(queryYear, serverYear)
  const selectedMonth = getSelectedMonth(
    queryMonth,
    serverMonth,
    navigationMode
  )
  const selectedDate = new Date(selectedYear, selectedMonth, 1)

  const currentDate = getCurrentDate(
    selectedDate,
    serverYear,
    serverMonth,
    options
  )

  let previousTitle
  let nextTitle
  let previousQuery
  let nextQuery

  switch (navigationMode) {
    case NAVIGATION_MODES.year: {
      const previousYear = getYear(addYears(currentDate, -1))
      const nextYear = getYear(addYears(currentDate, 1))
      previousTitle = previousYear
      nextTitle = nextYear
      previousQuery = { year: previousYear }
      nextQuery = { year: nextYear }
      break
    }
    case NAVIGATION_MODES.month: {
      const previousMonth = addMonths(currentDate, -1)
      const nextMonth = addMonths(currentDate, 1)

      previousTitle = format(previousMonth, 'MMMM', {
        locale,
      })
      nextTitle = format(nextMonth, 'MMMM', {
        locale,
      })
      previousQuery = {
        year: getYear(previousMonth),
        month: getMonth(previousMonth) + 1,
      }
      nextQuery = {
        year: getYear(nextMonth),
        month: getMonth(nextMonth) + 1,
      }
      break
    }
    default:
      break
  }

  const isAtStart = getIsAtStart(currentDate, options)
  const isAtEnd = getIsAtEnd(currentDate, options, navigationMode)
  const routeParamsFn = options.getLinkParams || defaultGetLinkParams

  return {
    previous: isAtStart
      ? null
      : {
          title: previousTitle,
          ...routeParamsFn(asPath, previousQuery),
        },
    next: isAtEnd
      ? null
      : {
          title: nextTitle,
          ...routeParamsFn(asPath, nextQuery),
        },
  }
}

const defaultGetLinkParams = (asPath, queryParams) => ({
  href: queryStringSetRelative(asPath, queryParams),
})

const getCurrentDate = (selectedDate, serverYear, serverMonth, options) => {
  // Selected date can't be in the future (or after endDate option if provided)
  if (isFuture(selectedDate)) {
    if (options.endDate) {
      return isBefore(selectedDate, options.endDate)
        ? selectedDate
        : options.endDate
    }
    return new Date(serverYear, serverMonth)
  }
  // Selected date can't be before startDate option
  if (getIsAtStart(selectedDate, options)) return options.startDate
  return selectedDate
}

const getSelectedYear = (queryYear, serverYear) => {
  if (queryYear && Number.isInteger(parseInt(queryYear))) {
    return queryYear
  }

  return serverYear
}

const getSelectedMonth = (queryMonth, serverMonth, navigationMode) => {
  if (queryMonth && Number.isInteger(parseInt(queryMonth))) {
    return parseInt(queryMonth - 1)
  }

  return navigationMode === NAVIGATION_MODES.year ? null : serverMonth
}

const getNavigationMode = (queryMonth, options) => {
  if (options.modes?.[NAVIGATION_MODES.year] && !queryMonth) {
    return NAVIGATION_MODES.year
  }
  return NAVIGATION_MODES.month // Monthly navigation always enabled for now, even if option is false
}

const getIsAtStart = (currentDate, options) => {
  if (!options.startDate) return false
  return !isAfter(currentDate, options.startDate)
}

const getIsAtEnd = (currentDate, options, navigationMode) => {
  if (options.endDate) {
    return !isBefore(currentDate, options.endDate)
  }

  switch (navigationMode) {
    case NAVIGATION_MODES.year:
      return isThisYear(currentDate)
    case NAVIGATION_MODES.month:
      return isThisMonth(currentDate)
    default:
      return false
  }
}

const NAVIGATION_MODES = {
  year: 'year',
  month: 'month',
}

export default useDateArchiveNavigation
