import { useRouter } from 'next/router'
import { useCallback } from 'react'

const useFilterRemove = ({
  defaultToIgnore = [],
  removeFromPath = false,
} = {}) => {
  const { pathname, push, query } = useRouter()

  const getQueryWithoutFilters = useCallback(
    (...filters) => {
      return Object.entries(query).reduce((acc, [key, value]) => {
        if (filters.includes(key)) return acc

        return { ...acc, [key]: value }
      }, {})
    },
    [query]
  )

  const getPathnameWithoutFilters = useCallback(
    (...filters) => {
      // Remove all of the specified filter keys inside square brackets (next.js syntax)
      // e.g. if removing the filter `foo`, removes `/[foo]` from the pathname
      const pathnameRegExp = new RegExp(`\\/\\[(${filters.join('|')})\\]`, 'g')
      const pathnameWithoutFilters = pathname.replace(pathnameRegExp, '')

      return pathnameWithoutFilters
    },
    [pathname]
  )

  const handleRemoveFilter = useCallback(
    (...filters) => {
      const queryWithoutFilters = getQueryWithoutFilters(
        ...filters,
        ...defaultToIgnore
      )

      push({
        pathname: removeFromPath
          ? getPathnameWithoutFilters(...filters, ...defaultToIgnore)
          : pathname,
        query: queryWithoutFilters,
      })
    },
    [
      pathname,
      push,
      defaultToIgnore,
      removeFromPath,
      getQueryWithoutFilters,
      getPathnameWithoutFilters,
    ]
  )

  return handleRemoveFilter
}

export default useFilterRemove
