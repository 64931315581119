import { utcToZonedTime, formatInTimeZone } from 'date-fns-tz'
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
} from 'react'

interface TimeZoneContextType {
  utcToZoned: (date: Date) => Date
  formatZoned: (date: Date, format?: string) => string
}

const TimeZoneContext = createContext<TimeZoneContextType>({
  utcToZoned: undefined,
  formatZoned: undefined,
})

const TimeZoneProvider = ({
  children,
  ianaTimeZone,
}: PropsWithChildren<{ ianaTimeZone: string }>) => {
  const timezone =
    ianaTimeZone ??
    Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ??
    'Etc/UTC'
  const utcToZoned = (date) => utcToZonedTime(date, timezone)

  const formatZoned = useCallback(
    (date, format = 'yyyy-MM-dd HH:mm:ss') =>
      formatInTimeZone(date, timezone, format),
    [timezone]
  )

  return (
    <TimeZoneContext.Provider value={{ utcToZoned, formatZoned }}>
      {children}
    </TimeZoneContext.Provider>
  )
}

const useTimeZoneContext = () => useContext(TimeZoneContext)

const useConvertToZonedTime = () => {
  const { utcToZoned } = useTimeZoneContext()
  return utcToZoned
}

const useFormatInTimeZone = () => {
  const { formatZoned } = useTimeZoneContext()
  return formatZoned
}

export { TimeZoneProvider, useConvertToZonedTime, useFormatInTimeZone }
