import PropTypes from 'prop-types'
import {
  Divider,
  Panel,
  Alignment,
  Layout,
  Section,
  shades,
  states,
} from '@resident-advisor/design-system'
import styled from 'styled-components'
import Link, { hrefAsPropTypes } from 'components/generic/link'
import { zIndex } from 'themes'
import NavigationItem from './components/NavigationItem'
import PaginationHeadLinks from './PaginationHeadLinks'

const panelStyles = {
  position: 'absolute',
  boxSizing: 'border-box',
  width: '50%',
}

const buildPanelStyles = (type) =>
  type === 'previous'
    ? { ...panelStyles, left: '0px' }
    : { ...panelStyles, right: '0px' }

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: auto;
  opacity: 0;
`

const ArchiveNavigator = ({ previous, next, shade = shades.light }) => {
  return (
    <>
      <PaginationHeadLinks prev={previous} next={next} />
      <Alignment flexDirection="row">
        <Panel
          shade={shades[shade]}
          state={previous ? states.default : states.disabled}
          aria-hidden="true"
          flexDirection="column"
          css={buildPanelStyles('previous')}
        >
          {previous && (
            <StyledLink noStyle href={previous.href} forwardedAs={previous.as}>
              {previous.title}
            </StyledLink>
          )}
          <NavigationItem type="previous" isHidden {...previous} />
        </Panel>

        <Panel
          shade={shades[shade]}
          state={next ? states.default : states.disabled}
          aria-hidden="true"
          flexDirection="column"
          css={buildPanelStyles('next')}
        >
          {next && (
            <StyledLink noStyle href={next.href} forwardedAs={next.as}>
              {next.title}
            </StyledLink>
          )}
          <NavigationItem type="next" isHidden {...next} />
        </Panel>

        <Section width="100%">
          <Layout>
            <Alignment
              flexDirection="row"
              css={{ pointerEvents: 'none' }}
              zIndex={zIndex.archiveNavigationItem}
              height="100%"
            >
              <NavigationItem
                type="previous"
                isHidden={!previous}
                {...previous}
              />
              <Divider vertical />
              <NavigationItem type="next" isHidden={!next} {...next} />
            </Alignment>
          </Layout>
        </Section>
      </Alignment>
    </>
  )
}

const navigationShape = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,

  href: hrefAsPropTypes.isRequired,
  as: hrefAsPropTypes,
}

ArchiveNavigator.propTypes = {
  shade: PropTypes.string,
  previous: PropTypes.shape(navigationShape),
  next: PropTypes.shape(navigationShape),
}

export default ArchiveNavigator
