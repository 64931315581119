import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const ResponsiveWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  ${({ paddingTop }) => css`
    &:before {
      padding-top: ${paddingTop};
      content: '';
      display: block;
    }
  `}
`

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`

const ResponsiveIframe = ({ src }) => (
  <ResponsiveWrapper paddingTop="56.25%">
    <StyledIframe src={src} />
  </ResponsiveWrapper>
)

ResponsiveIframe.propTypes = {
  src: PropTypes.string.isRequired,
}

export default ResponsiveIframe
