import { DocumentNode, QueryResult, useQuery } from '@apollo/client'
import { useState } from 'react'
import { UseQueryResultOptions } from 'components/generic/query-result-handler'
import get from 'lodash/get'
import set from 'lodash/set'
import merge from 'lodash/merge'
import { differenceInMilliseconds } from 'date-fns'
import getConfig from 'next/config'

import { isFooterInView } from 'components/shared/footer'

const {
  publicRuntimeConfig: { INFINITE_SCROLL_CONFIG },
} = getConfig()

const useQueryWithPagination = (
  query: DocumentNode,
  {
    variables,
    dataKey,
    maxPages,
    cancelInterval = parseInt(
      INFINITE_SCROLL_CONFIG.INFINITE_SCROLL_CANCEL_INTERVAL_IN_MILLISECONDS,
      10
    ),
    onLoadNextPage = () => null,
    paginationType = PaginationType.Cancellable,
    fetchPolicy = null,
    secondaryDataKey,
  }: UseQueryWithPaginationOptions
): UseQueryWithPaginationResult => {
  const [lastFetchTime, setLastFetchTime] = useState(null)
  const [cancelled, setCancelled] = useState(false)
  const [pagesLoaded, setPagesLoaded] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [fetchingNextPage, setFetchingNextPage] = useState(false)

  const queryResult = useQuery(query, {
    variables,
    onCompleted: (data) => {
      const firstPage = get(data, dataKey)
      const reachedMaxOnFirstPage =
        !firstPage || firstPage.length < variables.pageSize

      // If variables change without reloading the page, `pagesLoaded` persists
      // Therefore ensure it resets whenever the initial query completes
      setPagesLoaded(1)
      setHasMore(!reachedMaxOnFirstPage)
    },
    fetchPolicy,
  })

  let finishedPaginating = (maxPages && pagesLoaded >= maxPages) || !hasMore

  const fetchNextPage = (args?: boolean | number) => {
    // fetchNextPage is called by react-infinite-scroll with the call number (that we're not using)
    // so explicitly check whether it's the bool flag
    const manuallyClicked = args === true

    if (queryResult.loading || fetchingNextPage || finishedPaginating) {
      return
    }

    if (cancelled) {
      if (manuallyClicked) {
        setCancelled(false)
      } else {
        return
      }
    }

    if (paginationType === PaginationType.Cancellable && cancelInterval) {
      // cancel pagination if fetched faster than the cancel interval
      const now = new Date()

      if (lastFetchTime !== null) {
        const interval = differenceInMilliseconds(now, lastFetchTime)

        if (interval < cancelInterval && !manuallyClicked) {
          setCancelled(true)
          return
        }
      }

      setLastFetchTime(now)
    }

    setFetchingNextPage(true)

    const nextPageNumber = getNextPageNumber(
      queryResult.data,
      dataKey,
      variables
    )

    let cancelledInProgressQuery = false
    queryResult
      .fetchMore({
        variables: {
          page: nextPageNumber,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            paginationType === PaginationType.Cancellable &&
            !manuallyClicked &&
            pagesLoaded === 1 &&
            isFooterInView()
          ) {
            // User scrolled fast enough to see the footer before the first page loaded,
            // cancel the loading in this case.
            // For subsequent pages use the cancel interval logic
            cancelledInProgressQuery = true
            setCancelled(true)
            setFetchingNextPage(false)
            return prev
          }

          if (!fetchMoreResult) return prev

          finishedPaginating = maxPages && pagesLoaded + 1 >= maxPages
          setPagesLoaded(pagesLoaded + 1)
          setFetchingNextPage(false)

          const latestData = get(fetchMoreResult, dataKey)

          if (!latestData || latestData.length < variables.pageSize) {
            // We requested pageSize, but we got less
            setHasMore(false)
            finishedPaginating = true

            if (!latestData?.length) {
              return prev
            }
          }

          const resultData = set({}, dataKey, [
            ...get(prev, dataKey),
            ...latestData,
          ])
          let mergedData = merge(resultData, prev)

          if (secondaryDataKey) {
            const latestSecondaryData = get(fetchMoreResult, secondaryDataKey)

            if (latestSecondaryData) {
              const secondaryResultData = set({}, secondaryDataKey, [
                ...get(prev, secondaryDataKey),
                ...latestSecondaryData,
              ])
              mergedData = merge(secondaryResultData, mergedData)
            }
          }

          return mergedData
        },
      })
      .then(() => {
        if (!cancelledInProgressQuery) {
          onLoadNextPage(nextPageNumber, finishedPaginating)
        }
      })
  }

  // Note: hasMore might return true even if there are no more results ...
  // ... this will only happen if the resultSetSize % pageSize === 0
  //
  // E.g.:
  // If we have 10 results on the server and pageSize is 5, after fetching the
  // 2nd page we will assume there are more results as we just got a full page;
  // however, the third page will be empty
  return {
    ...queryResult,
    fetchNextPage,
    fetchingNextPage,
    finishedPaginating,
  }
}

enum PaginationType {
  Cancellable = 'cancellable',
  Uncancellable = 'uncancellable',
}

type UseQueryWithPaginationOptions = UseQueryResultOptions & {
  maxPages?: number
  cancelInterval?: number
  onLoadNextPage?: (
    nextPageNumber: number,
    finishedPaginating: boolean
  ) => unknown
  paginationType?: PaginationType
  secondaryDataKey?: string
}

type UseQueryWithPaginationResult = QueryResult & {
  fetchNextPage: (args?: boolean | number) => void
  fetchingNextPage: boolean
  finishedPaginating: boolean
}

const getNextPageNumber = (data, dataKey, variables) => {
  const currentNumberResults = get(data, dataKey)?.length || 0
  return (
    Math.floor(currentNumberResults / variables.pageSize) +
    (variables.page || 1)
  )
}

export { PaginationType }
export default useQueryWithPagination
