import Head from 'next/head'
import PropTypes from 'prop-types'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const getLinkHref = (link) => {
  if (!link) {
    return null
  }

  if (link.as) {
    let queryString = ''

    if (link.as.query) {
      const params = Object.keys(link.as.query)
        .map((key) => `${key}=${link.as.query[key]}`)
        .join('&')

      queryString = `?${params}`
    }

    return `${publicRuntimeConfig.NEXT_WEB_ADDRESS}${link.as.pathname}${queryString}`
  }

  return `${publicRuntimeConfig.NEXT_WEB_ADDRESS}${link.href}`
}

const PaginationHeadLinks = ({ prev, next }) => {
  const previousHref = getLinkHref(prev)
  const nextHref = getLinkHref(next)

  return (
    <Head>
      {previousHref && (
        <link rel="prev" href={previousHref} key={previousHref} />
      )}
      {nextHref && <link rel="next" href={nextHref} key={nextHref} />}
    </Head>
  )
}

PaginationHeadLinks.propTypes = {
  prev: PropTypes.object,
  next: PropTypes.object,
}

export default PaginationHeadLinks
export { getLinkHref }
