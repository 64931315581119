import PropTypes from 'prop-types'

const IframeEmbed = ({ src, height }) => (
  <iframe
    src={src}
    width="100%"
    title={src}
    height={height}
    scrolling="no"
    frameBorder="no"
  />
)

IframeEmbed.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.string,
}

export default IframeEmbed
