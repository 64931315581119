import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import queryStringSet, { queryStringSetRelative } from 'lib/queryStringSet'

const { publicRuntimeConfig } = getConfig()

const useInfiniteScrollPageNumber = () => {
  const { replace, query, asPath } = useRouter()

  const [initialAsPath, setInitialAsPath] = useState(asPath)
  const [initialPageNumber, setInitialPageNumber] = useState(
    parseInt(query.page) || 1
  )
  const [finishedPaginating, setFinishedPagination] = useState(false)

  useEffect(() => {
    if (queryParamsHaveChanged(asPath, initialAsPath)) {
      // Page has changed (i.e. filter change), so we need to reset state
      // i.e. /dj/foo?country=DE&page=3 => /dj/foo?country=UK
      setInitialAsPath(asPath)
      setInitialPageNumber(parseInt(query.page) || 1)
      setFinishedPagination(false)
    }
  }, [asPath, query.page, initialAsPath])

  // Push '&page={pageNumber}' to the URL using 'shallow' routing...
  // https://nextjs.org/docs/routing/shallow-routing
  // "Shallow routing allows you to change the URL without running data fetching methods"
  const onLoadNextPage = (nextPageNumber, finished) => {
    setFinishedPagination(finished)
    replace(getPageUrl(asPath, nextPageNumber), undefined, {
      shallow: true,
      scroll: false,
    })
  }

  return {
    initialPageNumber,
    onLoadNextPage,
    headPaginationAttributes: getHeadPaginationAttributes(
      asPath,
      parseInt(query.page) || 1,
      finishedPaginating
    ),
  }
}

const getHeadPaginationAttributes = (asPath, pageNumber, finished) => ({
  prev: pageNumber > 1 ? getPageUrl(asPath, pageNumber - 1, true) : null,
  next: !finished ? getPageUrl(asPath, pageNumber + 1, true) : null,
})

const getPageUrl = (asPath, page, absolute) =>
  absolute
    ? queryStringSet(`${publicRuntimeConfig.NEXT_WEB_ADDRESS}${asPath}`, {
        page,
      })
    : queryStringSetRelative(asPath, { page })

// Test whether the URL has changed (i.e. a filter has been added), ignoring
// the page parameter. This is done by creating new URLs where the page
// is equivalent and comparing those
const queryParamsHaveChanged = (initialQuery, newQuery) =>
  queryStringSetRelative(initialQuery, {
    page: 1,
  }) !== queryStringSetRelative(newQuery, { page: 1 })

export default useInfiniteScrollPageNumber
