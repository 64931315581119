import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

const Script = ({ charset, ...props }) => {
  const scriptRef = useRef()

  useEffect(() => {
    let script = null

    if (scriptRef.current && document) {
      script = document.createElement('script')

      script.src = props.src
      script.async = true

      document.body.appendChild(script)
    }

    return () => {
      if (script) {
        document.body.removeChild(script)
      }
    }
  }, [props.src, scriptRef])

  // eslint-disable-next-line react/no-unknown-property
  return <script {...props} charSet={charset} ref={scriptRef} />
}

Script.propTypes = {
  src: PropTypes.string.isRequired,
  charset: PropTypes.string,
}

Script.defaultProps = {
  charset: 'utf-8',
}

export default Script
