/* eslint-disable xss/no-mixed-html */
import styled from 'styled-components'
import parse, { DOMNode } from 'html-react-parser'
import { Text, variants } from '@resident-advisor/design-system'
import ErrorBoundary from 'components/generic/error-boundary'
import { SpaceProps } from 'styled-system'
import defaultReplace from './replace'

const CmsContent = ({
  content = '',
  textVariant = variants.text.longform,
  replace = defaultReplace,
  textProps,
  ...props
}: CmsContentProps) => {
  if (!content) {
    return null
  }

  return (
    <ErrorBoundary>
      <CmsContentMarkdown
        content={content}
        textVariant={textVariant}
        replace={replace}
        textProps={textProps}
        {...props}
      />
    </ErrorBoundary>
  )
}

const CmsContentMarkdown = ({
  content = '',
  textVariant = variants.text.longform,
  replace = defaultReplace,
  textProps = {},
}: CmsContentProps) => {
  const parsedHtml = parse(content, {
    replace: (node) => replace(node, textVariant),
  })

  return (
    <StyledText variant={textVariant} {...textProps}>
      {parsedHtml}
    </StyledText>
  )
}

type CmsContentProps = {
  content?: string
  color?: string
  textVariant?: string
  replace?: (node: DOMNode, textVariant: string) => JSX.Element | DOMNode
  textProps?: TextProps
  pt?: SpaceProps['pt']
}

type TextProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string | number | symbol]: any
}

const StyledText = styled(Text)`
  white-space: pre-wrap;
`

export { CmsContentMarkdown }
export default CmsContent
