import styled from 'styled-components'
import PropTypes from 'prop-types'
import { padding } from 'themes'
import { space } from 'styled-system'

/*
 * Component that adds standard padding to its children.
 *
 * If there are no children, collapses to zero height.
 * For adjacent children, the padding between them is the same as the standard
 * padding-bottom.
 */
const Padded = ({ children }) =>
  children && <PaddedChildren {...padding}>{children}</PaddedChildren>

Padded.propTypes = {
  children: PropTypes.node.isRequired,
}

const PaddedChildren = styled.div`
  > * {
    ${space}
  }

  > * + * {
    padding-top: 0;
  }
`
export default Padded
